import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { isLoggedIn } from '../services/authService';
import { useDispatch } from 'react-redux';
import * as actions from '../store/user/action-types';
import { CircularProgress, Container, Typography } from '../components/UIComponents';
import { ROLES } from '../constants/universal';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100vh',
		width: '100vw',
		justifyContent: 'center'
	},
	spacing: {
		marginBottom: theme.spacing(2)
	}
}));

export default function Home() {
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		const checkLoggedIn = async () => {
			const user = await isLoggedIn();
			if (user) {
				dispatch({ type: actions.SAVE_USER, payload: user });
				const isAdmin = user.roles.some(
					(rol) => rol === ROLES.ADMINS || rol === ROLES.SUPPORT || rol === ROLES.DEVELOPERS
				);
				const isShipping = user.roles.some((rol) => rol === ROLES.OPERATOR);

				if (isAdmin) {
					navigate('/app/box-list');
				}

				if (isShipping) {
					navigate('/app/box-list-shipping');
				}
			} else {
				navigate('/app/login');
			}
		};

		checkLoggedIn();
	}, [dispatch]);

	return (
		<Container className={classes.root}>
			<CircularProgress className={classes.spacing} />
			<Typography variant="caption" component="div">
				Loading page...
			</Typography>
		</Container>
	);
}
